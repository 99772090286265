import * as React from 'react'

import { Helmet } from 'react-helmet'

import '../components/variables.css'
import '../components/global.css'

const NotFoundPage = () => (
  <div className="container">
    <Helmet title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </div>
)

export default NotFoundPage
